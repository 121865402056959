<template>
  <div>
    <v-dialog
      v-model="dialog"
      :max-width="dialogMaxWidth"
      :fullscreen="dialogFullscreen"
      class="border"
    >
      <v-card flat>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>Registro de Empresa</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="dialog-scrollable pt-3">
          <v-progress-linear
            :value="(e6 / 4) * 100"
            color="primary"
            height="10"
          ></v-progress-linear>

          <v-stepper v-model="e6" color="primary" flat>
            <v-stepper-header>
              <template v-for="n in 3">
                <v-stepper-step
                  :key="`${n}-step`"
                  :complete="e6 > n || isStepComplete[n - 1]"
                  :step="n"
                  editable
                  @click="e6 = n"
                >
                  {{ stepTitles[n - 1] }}
                </v-stepper-step>

                <v-divider v-if="n !== 4" :key="n"></v-divider>
              </template>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <transition name="fade">
                  <v-card flat>
                    <v-card-text>
                      <h3>Verificación de Número de Teléfono</h3>
                      <p>
                        Vamos a verificar su número de celular. Enviaremos un mensaje de
                        texto con un PIN de 6 dígitos.
                      </p>
                      <v-form
                        ref="form1"
                        v-model="valid"
                        @submit.prevent="sendPhoneVerification"
                      >
                        <v-text-field
                          v-model="company.representativeName"
                          label="Nombre del Representante Legal"
                          :rules="[(v) => !!v || 'Campo requerido']"
                          @input="saveState"
                          required
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                        <v-text-field
                          v-model="company.email"
                          label="Correo Electrónico"
                          :rules="emailRules"
                          @input="saveState"
                          required
                          outlined
                          hide-details="auto"
                        >
                        </v-text-field>
                        <v-text-field
                          v-model="company.phone"
                          label="Número de Teléfono"
                          :rules="phoneRules"
                          @input="formatPhoneNumber"
                          @keypress="onlyNumbers"
                          required
                          outlined
                          dense
                          hide-details="auto"
                        >
                          <template v-slot:append>
                            <v-btn
                              large
                              color="primary"
                              class="mb-2"
                              :disabled="!valid || phoneVerificationSent"
                              :loading="isVerifyingPhone"
                              @click="sendPhoneVerification"
                            >
                              Enviar SMS
                            </v-btn>
                          </template>
                        </v-text-field>
                        <v-otp-input
                          v-if="phoneVerificationSent"
                          v-model="phonePin"
                          length="6"
                          @finish="verifyPhonePin"
                        ></v-otp-input>
                      </v-form>
                    </v-card-text>
                    <!-- <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="nextStep" :disabled="!phoneVerified">
                        Siguiente
                      </v-btn>
                    </v-card-actions> -->
                  </v-card>
                </transition>
              </v-stepper-content>

              <!-- Repeat similar structure for steps 2, 3, and 4 -->
            </v-stepper-items>

            <v-stepper-content step="2">
              <transition name="fade">
                <v-card flat>
                  <v-card-text>
                    <h3>Verificación de Documento</h3>
                    <p>
                      Por favor, proporcione su NIT, RUT o cédula para verificar su
                      identidad en la DIAN.
                    </p>
                    <v-form ref="form3" v-model="valid" @submit.prevent="verifyDocument">
                      <v-row>
                        <v-col cols="10">
                          <v-text-field
                            v-model="company.document"
                            label="NIT/RUT/Cédula"
                            :rules="documentRules"
                            @input="saveState"
                            required
                            outlined
                            hide-details="auto"
                          >
                            <template v-slot:append>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on"
                                    >mdi-help-circle-outline</v-icon
                                  >
                                </template>
                                <span
                                  >Ingrese su NIT, RUT o número de cédula sin puntos ni
                                  guiones</span
                                >
                              </v-tooltip>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="2">
                          <v-btn large block color="success" @click="verifyDocument">
                            <v-icon>mdi-magnify</v-icon>
                          </v-btn>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-if="documentVerified"
                            v-model="company.documentName"
                            label="Nombre Registrado"
                            outlined
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <!-- <v-btn color="secondary" @click="prevStep">Anterior</v-btn> -->
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="nextStep" :disabled="!documentVerified"
                      >Siguiente</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </transition>
            </v-stepper-content>

            <v-stepper-content step="3">
              <transition name="fade">
                <v-card flat>
                  <v-card-text>
                    <h3>Contraseña de acceso</h3>
                    <p>Seleccione una contraseña segura para acceder a su cuenta.</p>
                    <v-form
                      ref="form4"
                      v-model="valid"
                      @submit.prevent="submitRegistration"
                    >
                      <v-text-field
                        v-model="company.password"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'"
                        label="Contraseña"
                        :rules="passwordRules"
                        @input="saveState"
                        @click:append="showPassword = !showPassword"
                        required
                        outlined
                        hide-details="auto"
                      ></v-text-field>
                      <v-progress-linear
                        :value="passwordStrength"
                        :color="passwordStrengthColor"
                        height="7"
                      ></v-progress-linear>
                      <v-text-field
                        v-model="confirmPassword"
                        :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showConfirmPassword ? 'text' : 'password'"
                        label="Confirmar Contraseña"
                        :rules="confirmPasswordRules"
                        @input="saveState"
                        @click:append="showConfirmPassword = !showConfirmPassword"
                        required
                        outlined
                        hide-details="auto"
                      ></v-text-field>
                    </v-form>
                    <p>
                      La confirmación del registro se enviará al correo del representante
                      legal.
                    </p>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="secondary" @click="prevStep">Anterior</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="showSummary" :disabled="!valid"
                      >Revisar y Enviar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </transition>
            </v-stepper-content>
          </v-stepper>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="summaryDialog" max-width="500px">
      <v-card>
        <v-card-title>Resumen de Registro</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item v-for="(value, key) in companySummary" :key="key">
              <v-list-item-content>
                <v-list-item-title>{{ key }}</v-list-item-title>
                <v-list-item-subtitle>{{ value }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="summaryDialog = false">Editar</v-btn>
          <v-btn color="primary" @click="submitRegistration" :disabled="!allFieldsValid">
            Confirmar y Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogVisible" max-width="500px">
      <v-card>
        <v-card-title>Información</v-card-title>
        <v-card-text>{{ dialogMessage }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogVisible = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { webserver } from "../services/webserver.js";
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      summaryDialog: false,
      dialogMessage: "",
      dialogVisible: false,
      e6: parseInt(localStorage.getItem("step")) || 1,
      valid: false,
      phonePin: "",
      phoneVerified: false,
      emailPin: "",
      emailVerified: false,
      documentVerified: false,
      confirmPassword: "",
      company: {
        phone: "",
        email: "",
        document: "",
        documentName: "",
        representativeName: "",
        password: "",
      },
      phoneVerificationSent: false,
      emailVerificationSent: false,
      showPassword: false,
      showConfirmPassword: false,
      isVerifyingPhone: false,
      isVerifyingEmail: false,
      isVerifyingDocument: false,
      stepTitles: ["Contacto", "Rut / Nit / Cédula", "Crear Cuenta"],
      phoneRules: [
        (v) => !!v || "El número de teléfono es requerido",
        (v) => v.replace(/\D/g, "").length === 10 || "El número debe tener 10 dígitos",
      ],
      emailRules: [
        (v) => !!v || "El correo electrónico es requerido",
        (v) => /.+@.+\..+/.test(v) || "El correo electrónico debe ser válido",
      ],
      documentRules: [
        (v) => !!v || "El documento es requerido",
        (v) => /^\d+$/.test(v) || "El documento debe contener solo números",
      ],
      passwordRules: [
        (v) => !!v || "La contraseña es requerida",
        (v) => v.length >= 8 || "La contraseña debe tener al menos 8 caracteres",
        (v) =>
          /[A-Z]/.test(v) || "La contraseña debe contener al menos una letra mayúscula",
        (v) =>
          /[a-z]/.test(v) || "La contraseña debe contener al menos una letra minúscula",
        (v) => /[0-9]/.test(v) || "La contraseña debe contener al menos un número",
        (v) =>
          /[!@#$%^&*()]/.test(v) ||
          "La contraseña debe contener al menos un carácter especial",
      ],
      isStepComplete: [false, false, false, false],
      generatedPhonePin: null,
      generatedEmailPin: null,
    };
  },
  computed: {
    dialogFullscreen() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    dialogMaxWidth() {
      return this.$vuetify.breakpoint.xsOnly ? "100%" : "800px";
    },
    passwordStrength() {
      let strength = 0;
      const password = this.company.password;
      if (password.length >= 8) strength += 20;
      if (/[A-Z]/.test(password)) strength += 20;
      if (/[a-z]/.test(password)) strength += 20;
      if (/[0-9]/.test(password)) strength += 20;
      if (/[!@#$%^&*()]/.test(password)) strength += 20;
      return strength;
    },
    passwordStrengthColor() {
      if (this.passwordStrength < 40) return "red";
      if (this.passwordStrength < 70) return "orange";
      return "green";
    },
    confirmPasswordRules() {
      return [
        (v) => !!v || "La confirmación de contraseña es requerida",
        (v) => v === this.company.password || "Las contraseñas no coinciden",
      ];
    },
    companySummary() {
      return {
        Teléfono: this.company.phone,
        "Correo Electrónico": this.company.email,
        Documento: this.company.document,
        "Nombre Registrado": this.company.documentName,
        "Nombre del Representante": this.company.representativeName,
      };
    },
    allFieldsValid() {
      return (
        this.company.phone &&
        this.company.email &&
        this.company.document &&
        this.company.documentName &&
        this.company.representativeName &&
        this.company.password &&
        this.confirmPassword &&
        this.company.password === this.confirmPassword
      );
    },
  },
  methods: {
    close() {
      this.$emit("close", true);
    },
    saveState() {
      localStorage.setItem("step", this.e6);
      localStorage.setItem("company", JSON.stringify(this.company));
    },
    formatPhoneNumber() {
      let number = this.company.phone.replace(/\D/g, "");
      if (number.length > 10) {
        number = number.slice(0, 10);
      }
      if (number.length > 6) {
        this.company.phone = `(${number.slice(0, 3)}) ${number.slice(
          3,
          6
        )}-${number.slice(6)}`;
      } else if (number.length > 3) {
        this.company.phone = `(${number.slice(0, 3)}) ${number.slice(3)}`;
      } else {
        this.company.phone = number;
      }
    },
    onlyNumbers(event) {
      let keyCode = event.keyCode ? event.keyCode : event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        event.preventDefault();
      }
    },
    sendPhoneVerification() {
      this.isVerifyingPhone = true;
      setTimeout(() => {
        this.phoneVerificationSent = true;
        this.isVerifyingPhone = false;
        this.dialogMessage = "SMS enviado con éxito";
        this.dialogVisible = true;
        this.saveState();
      }, 2000);
    },

    // Include similar methods for email verification, document verification, etc.
    nextStep() {
      if (this.e6 < 4) this.e6++;
      this.saveState();
    },
    prevStep() {
      if (this.e6 > 1) this.e6--;
      this.saveState();
    },
    showSummary() {
      this.summaryDialog = true;
    },
    async submitRegistration() {
      if (!this.allFieldsValid) {
        this.dialogMessage = "Por favor complete todos los campos";
        this.dialogVisible = true;
        return;
      }

      try {
        this.loading = true;
        this.errorMessage = "";
        const result = await this.callWebServer("createCompany", this.company);

        if (result.error) {
          this.loading = false;
          this.errorMessage = result.error;
          throw new Error(result.error);
        } else {
          this.dialogMessage = "Registro completado con éxito";
          this.dialogVisible = true;
          this.dialog = false;
          this.summaryDialog = false;
          //localStorage.clear();
          //this.resetForm();
        }
      } catch (error) {
        console.error("Error adding message:", error);
        this.errorMessage = "Failed to add message. Please try again.";
        throw error; // Re-throw if you want calling code to handle it
      } finally {
        this.loading = false;
      }
    },
    resetForm() {
      this.e6 = 1;
      this.phonePin = "";
      this.phoneVerified = false;
      this.emailPin = "";
      this.emailVerified = false;
      this.documentVerified = false;
      this.confirmPassword = "";
      this.company = {
        phone: "",
        email: "",
        document: "",
        documentName: "",
        documentEmail: "",
        representativeName: "",
        representativeEmail: "",
        password: "",
      };
      this.phoneVerificationSent = false;
      this.emailVerificationSent = false;
    },
    generatePin() {
      return Math.floor(100000 + Math.random() * 900000).toString();
    },
    async sendPhoneVerification() {
      this.isVerifyingPhone = true;
      this.generatedPhonePin = this.generatePin();
      var phone = this.company.phone.replace(/\D/g, "");
      var messageData = {
        phoneNumber: phone,
        msg: "Su PIN de verificación es: " + this.generatedPhonePin,
      };
      try {
        this.loading = true;
        this.errorMessage = "";
        const result = await this.callWebServer("sendSMS", messageData);
        if (result.error) {
          this.errorMessage = result.error;
          throw new Error(result.error);
        } else {
          this.phoneVerificationSent = true;
          this.isVerifyingPhone = false;
          this.dialogMessage = `SMS enviado con éxito.`;
          this.dialogVisible = true;
          console.log("Message added successfully:", result);
          return result;
        }
      } catch (error) {
        console.error("Error adding message:", error);
        this.errorMessage = "Failed to add message. Please try again.";
        throw error; // Re-throw if you want calling code to handle it
      } finally {
        this.loading = false;
      }
    },
    callWebServer(method, data) {
      return new Promise((resolve, reject) => {
        webserver(
          method,
          data,
          function (response) {
            resolve(response);
          },
          function (error) {
            reject(error);
          }
        );
      });
    },
    verifyPhonePin() {
      if (this.phonePin === this.generatedPhonePin) {
        this.phoneVerified = true;
        this.phonePin = "";
        this.isStepComplete[0] = true;
        this.saveState();
        this.nextStep();
        // this.dialogMessage = "Número de teléfono verificado correctamente";
        // this.dialogVisible = true;
      } else {
        this.dialogMessage = "PIN incorrecto. Por favor, intente nuevamente.";
        this.dialogVisible = true;
      }
    },
    async send_email(to, subject, message) {
      var messageData = {
        email: to,
        subject: subject,
        msg: message,
      };

      try {
        this.loading = true;
        this.errorMessage = "";
        const result = await this.callWebServer("send_email", messageData);
        if (result.error) {
          this.loading = false;
          this.emailVerified = false;
          this.errorMessage = result.error;
          throw new Error(result.error);
        } else {
          this.emailVerificationSent = true;
          this.isVerifyingEmail = false;
          this.dialogMessage = "Correo enviado con éxito";
          this.dialogVisible = true;
          this.saveState();
        }
      } catch (error) {
        console.error("Error adding message:", error);
        this.errorMessage = "Failed to add message. Please try again.";
        throw error; // Re-throw if you want calling code to handle it
      } finally {
        this.loading = false;
      }
    },
    async sendMessage(envelope) {
      try {
        const result = await this.callWebServer("send_email", envelope);
        if (result.error) {
          throw new Error(result.error);
        }
        return result;
      } catch (error) {
        console.error(`Error al enviar email:`, error);
        throw error;
      }
    },
    async verifyDocument() {
      this.isVerifyingDocument = true;
      var messageData = {
        nit: this.company.document,
      };
      //console.log("Message data:", messageData);
      try {
        this.loading = true;
        this.errorMessage = "";
        const result = await this.callWebServer("get_rut", messageData);

        if (result.error) {
          this.loading = false;
          this.documentVerified = false;
          this.errorMessage = result.error;
          throw new Error(result.error);
        } else {
          this.documentVerified = true;
          if (result.businessName) {
            this.company.documentName = result.businessName;
            //this.company.documentEmail = result.email;
          } else {
            if (result.first_name) {
              this.company.documentName =
                result.first_name + " " + result.surname + " " + result.second_surname;
            }
          }

          this.isVerifyingDocument = false;
          this.dialogMessage = "Documento verificado";
          this.dialogVisible = true;
          this.saveState();
        }
      } catch (error) {
        console.error("Error adding message:", error);
        this.errorMessage = "Failed to add message. Please try again.";
        throw error; // Re-throw if you want calling code to handle it
      } finally {
        this.loading = false;
      }
    },
    nextStep() {
      if (this.e6 < 4) this.e6++;
      this.saveState();
    },
    prevStep() {
      if (this.e6 > 1) this.e6--;
      this.saveState();
    },
    showSummary() {
      this.summaryDialog = true;
    },
    // submitRegistration() {
    //   if (!this.allFieldsValid) {
    //     this.dialogMessage = "Por favor complete todos los campos";
    //     this.dialogVisible = true;
    //     return;
    //   }
    //   var userData = {
    //     name: this.company.representativeName,
    //     phone: this.company.phone,
    //     email: this.company.email,
    //     store_id: this.company.document,
    //     store_name: this.company.documentName,
    //     password: this.company.password,
    //   };

    //   // console.log("Datos de registro:", response);
    //   // this.dialogMessage = "Registro completado con éxito";
    //   // this.dialogVisible = true;
    //   // this.dialog = false;
    //   // this.summaryDialog = false;
    //   //localStorage.clear();
    //   //this.resetForm();
    // },
    resetForm() {
      this.e6 = 1;
      this.phonePin = "";
      this.phoneVerified = false;
      this.emailPin = "";
      this.emailVerified = false;
      this.documentVerified = false;
      this.confirmPassword = "";
      this.company = {
        phone: "",
        email: "",
        document: "",
        documentName: "",
        documentEmail: "",
        representativeName: "",
        representativeEmail: "",
        password: "",
      };
      this.phoneVerificationSent = false;
      this.emailVerificationSent = false;
    },
    generateHtml(userData) {
      return `
        <!DOCTYPE html>
        <html lang="es">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Bienvenido a AI-POS</title>
          <style>
            body { font-family: Arial, sans-serif; line-height: 1.6; color: #333; }
            .container { max-width: 600px; margin: 0 auto; padding: 20px; border: 1px solid #ddd; border-radius: 5px; }
            .header { background-color: #4CAF50; color: white; text-align: center; padding: 10px; border-radius: 5px 5px 0 0; }
            .content { padding: 20px; }
            .footer { background-color: #f1f1f1; padding: 10px; text-align: center; font-size: 0.8em; border-radius: 0 0 5px 5px; }
          </style>
        </head>
        <body>
          <div class="container">
            <div class="header">
              <h1>¡Bienvenido a AI-POS!</h1>
            </div>
            <div class="content">
              <p>Estimado/a <strong>${userData.representativeName}</strong>,</p>
              <p>Nos complace informarle que su registro en AI-POS ha sido completado con éxito. A continuación, encontrará los detalles de su cuenta:</p>
              <ul>
                <li><strong>Nombre:</strong> ${userData.documentName}</li>
                <li><strong>Documento:</strong> ${userData.document}</li>
                <li><strong>Correo electrónico:</strong> ${userData.email}</li>
                <li><strong>Teléfono:</strong> ${userData.phone}</li>
              </ul>
              <p>Su cuenta está ahora activa y puede acceder a nuestro sistema utilizando su correo electrónico y la contraseña que estableció durante el registro.</p>
              <p>Si tiene alguna pregunta o necesita asistencia, no dude en contactarnos.</p>
              <p>¡Gracias por unirse a AI-POS! Esperamos que disfrute de nuestros servicios.</p>
            </div>
            <div class="footer">
              <p>Este es un correo electrónico automático. Por favor, no responda a este mensaje.</p>
            </div>
          </div>
        </body>
        </html>
      `;
    },
  },
  mounted() {
    const savedCompany = localStorage.getItem("company");
    if (savedCompany) {
      this.company = JSON.parse(savedCompany);
    }
  },
};
</script>

<style scoped>
.dialog-scrollable {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.v-stepper__step--complete .v-stepper__step__content {
  color: #4caf50;
}

.v-stepper__step__step {
  background-color: #4caf50 !important;
}

.v-text-field {
  margin-bottom: 10px;
}

.border {
  border-radius: 20px !important;
  border: 2px solid white !important; /* Ajusta el grosor y el estilo del borde como desees */
}
</style>
