<template>
  <v-container class="pa-0 ma-0 login-container" fluid>
    <v-row no-gutters class="login-row">
      <!-- Right side - Login form -->

      <v-col
        cols="12"
        md="8"
        order="1"
        order-md="2"
        class="d-flex flex-column align-stretch right-background py-6"
      >
        <ai_agent :dialog="dialog" @close="dialog = false" class="mt-6" />
        <v-btn
          color="transaprent"
          elevation="0"
          fab
          fixed
          top
          right
          @click="dialog = true"
        >
          <v-icon large>mdi-comment-question-outline</v-icon>
        </v-btn>
        <div class="flex-grow-1 d-flex align-center justify-center" width="100%">
          <v-card flat max-width="400" width="100%" class="pa-8 transparent">
            <v-card-title class="text-h4 font-weight-bold mb-6">
              <transition appear name="slide-fade">
                <span>Login</span>
              </transition>
            </v-card-title>
            <v-form ref="form" v-model="valid" @submit.prevent="login">
              <transition-group appear name="fade-in" tag="div">
                <v-text-field
                  v-if="storelst.length === 0"
                  key="email"
                  v-model="credential.user_email"
                  :rules="emailRules"
                  label="Email"
                  prepend-inner-icon="mdi-account-outline"
                  outlined
                  dense
                  hide-details="auto"
                  class="mb-3"
                ></v-text-field>

                <v-text-field
                  v-if="storelst.length === 0"
                  key="password"
                  v-model="credential.user_password"
                  :rules="f_required"
                  label="Password"
                  prepend-inner-icon="mdi-lock-outline"
                  type="password"
                  outlined
                  dense
                  hide-details="auto"
                  class="mb-3"
                ></v-text-field>

                <v-select
                  v-if="storelst.length > 0"
                  key="store"
                  v-model="credential.store"
                  :items="storelst"
                  label="Production Center"
                  item-text="name"
                  item-value="store_id"
                  outlined
                  dense
                ></v-select>
              </transition-group>

              <transition appear name="fade-in-up">
                <v-btn
                  ref="mybtn"
                  outlined
                  dark
                  block
                  class="mt-3 success white--text"
                  :loading="loading_status"
                  :color="$vuetify.theme.themes.light.primary"
                  type="submit"
                  @click="validate"
                >
                  Ingresar
                </v-btn>
              </transition>
            </v-form>
          </v-card>
        </div>
      </v-col>

      <!-- Left side - Patterned background with grouped and centered content -->
      <v-col cols="12" md="4" order="2" order-md="1" class="left-background">
        <v-sheet
          height="100%"
          width="100%"
          class="d-flex flex-column align-stretch right-background py-6"
          :style="{ background: `${$vuetify.theme.themes.light.primary}` }"
        >
          <div class="flex-grow-1 d-flex align-center justify-center" width="100%">
            <div class="text-center">
              <v-card
                flat
                max-width="400"
                width="100%"
                class="pa-8 transparent justify-center"
              >
                <transition appear name="fade-in">
                  <v-img
                    :src="weblogo"
                    contain
                    height="120"
                    max-width="200"
                    class="mx-auto mb-6"
                  ></v-img>
                </transition>
                <transition appear name="slide-fade">
                  <h1 class="text-h3 font-weight-bold mb-4 white--text">AI-Pos</h1>
                </transition>
                <transition appear name="fade-in">
                  <p class="text-h6 white--text">Punto de venta Inteligente.</p>
                </transition>
                <transition appear name="fade-in-up">
                  <v-btn dark outlined text @click="sign_dialog = !sign_dialog">
                    Crear Cuenta
                  </v-btn>
                </transition>

                <SignIn
                  :dialog="sign_dialog"
                  @close="sign_dialog = !sign_dialog"
                  class="mt-6"
                />
              </v-card>
            </div>
          </div>

          <!-- Footer -->
          <v-footer dark class="mt-auto pa-4 transparent">
            <v-row justify="center" align="center" no-gutters>
              <v-col cols="12" class="text-center">
                <v-btn x-small text href="/terminos" class="mx-2"
                  >Términos y Condiciones</v-btn
                >
                <v-btn x-small text href="/privacidad" class="mx-2"
                  >Aviso de Privacidad</v-btn
                >
              </v-col>
              <v-col cols="12" class="text-center mt-2 caption">
                © {{ new Date().getFullYear() }} NGM Corp. Todos los derechos reservados.
              </v-col>
            </v-row>
          </v-footer>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// El script permanece sin cambios
import { webserver } from "../services/webserver.js";
import createProfile from "../utils/createProfile.js";
import createStore from "../utils/createStore.js";
import createUser from "../utils/createParty.js";
import ai_agent from "../components/ai/ai_agent_claude.vue";
import SignIn from "./SignIn.vue";

export default {
  name: "Login",
  props: {
    nextUrl: {
      default: null,
    },
  },
  components: { ai_agent, SignIn },
  beforeMount() {
    console.log("Login", this.$store.getters.manifest);
    const website = this.$store.getters.getHost;
    const root = website.replaceAll(".", "");
    this.weblogo = "./sites/" + root + "/logo.webp";
    console.log(this.weblogo);
  },
  mounted() {
    console.log(
      "Fuente de iconos cargada:",
      document.fonts.check('12px "Material Design Icons"')
    );
    this.$store.dispatch("setNavStatus", false);
    this.$store.dispatch("set_menu", false);
    this.$nextTick(() => {
      this.$forceUpdate();
    });
  },
  data() {
    return {
      sign_dialog: false,
      dialog: false,
      finger_dialog: false,
      weblogo: null,
      lhost: window.location.hostname,
      accept_terms: false,
      errors: [],
      valid: false,
      f_required: [(v) => !!v || "Requerido"],
      emailRules: [
        (v) => !!v || "El correo electrónico es requerido",
        (v) => /.+@.+\..+/.test(v) || "El correo electrónico debe ser válido",
      ],
      loading_status: false,
      credential: {
        store: null,
        user_email: null,
        user_password: "",
        token: null,
      },
      storelst: [],
      profile: createProfile(),
      company: createStore(),
      user: createUser(),
      manifest: {},
    };
  },
  methods: {
    // Los métodos permanecen sin cambios
    validate() {
      this.$refs.form.validate();
    },
    login(e) {
      e.preventDefault();
      if (this.$refs.form.validate()) {
        this.loading_status = true;
        var md5 = require("md5");
        var qry = {
          user_email: this.credential.user_email,
          user_password: md5(this.credential.user_password),
          user_token: this.credential.token,
          account: this.company.account,
          store: this.credential.store,
        };
        var metodo = "pre_login";
        if (this.credential.store) {
          metodo = "login";
        }

        webserver(metodo, qry, (data) => {
          console.log(metodo, "DATA", data);
          this.loading_status = false;
          if (metodo === "login") {
            this.profile.name = data.profile.user_name;
            this.profile.email = data.profile.user_email;
            this.profile.token = data.profile.token;
            this.profile.picture = data.profile.picture;
            this.profile.account = data.profile.account;
            this.profile.store = this.credential.store;
            this.profile.code = data.profile.code;
            this.profile.user_access = data.profile.user_access;
            this.profile.user_id = data.profile.user_id;

            this.$store.dispatch("setProfile", this.profile);
            this.$store.dispatch("setCompany", data.company);

            window.token = data.token;
            window.profile = this.profile;
            window.company = data.company;
            window.user = data.user;
            const encryptedText = this.CryptoJS.AES.encrypt(
              JSON.stringify(this.profile),
              process.env.VUE_APP_QR4
            ).toString();
            window.localStorage.setItem("sc_c", encryptedText);

            const encryptedStore = this.CryptoJS.AES.encrypt(
              JSON.stringify(data.company),
              process.env.VUE_APP_QR4
            ).toString();
            window.localStorage.setItem("sc_s", encryptedStore);

            this.$store.dispatch("setNavStatus", true);
            this.$store.dispatch("set_menu", true);

            if (this.nextUrl) {
              this.$router.push(this.nextUrl);
            } else {
              this.$router.push("/Welcome");
            }
          } else {
            // console.log("PRE_LOGIN", data);
            switch (true) {
              case data.length === 0:
                this.$store.dispatch("setToast", {
                  timeout_error: 3000,
                  dialog: true,
                  text: "Nombre de usaurio o contraseña incorrectos",
                  color: "red",
                });
                break;
              case data.length > 1:
                this.storelst = data;
                break;
              case data.length === 1:
                this.credential.store = data[0].store_id;
                this.$refs.mybtn.$el.click();
                break;
            }
          }
        });
      }
    },
  },
  watch: {
    "$store.getters.getManifest"(newVal) {
      if (newVal) {
        this.manifest = newVal;
      }
    },
    "$store.getters.company"(newVal) {
      if (newVal) {
        this.company = newVal;
      }
    },
  },
};
</script>

<style scoped>
.material-icons {
  font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased; /* Mejora la renderización en iOS */
}

.login-container {
  height: calc(100vh);
  position: relative;
  overflow-y: auto;
}

.login-row {
  min-height: 100%;
}

.left-background {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.1) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0.1) 75%,
    transparent 75%,
    transparent
  );
  background-size: 60px 60px;
}

.right-background {
  background-color: #f5f5f5;
}

.login-bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-enter {
  transform: translateX(-20px);
  opacity: 0;
}

.fade-in-enter-active {
  transition: opacity 0.5s;
}
.fade-in-enter {
  opacity: 0;
}

.fade-in-up-enter-active {
  transition: all 0.5s;
}
.fade-in-up-enter {
  transform: translateY(20px);
  opacity: 0;
}

.v-btn--floating {
  position: relative;
}

/* Ajuste para dispositivos móviles */
@media (max-width: 959px) {
  .login-container {
    height: calc(100vh);
    padding-bottom: 56px;
  }
}
</style>
