<template>
  <div>
    <ai_loading :isLoading="loading" />
  </div>
</template>

<script>
// import { webserver } from "../services/webserver.js";
import ai_loading from "../components/ai/ai_loading.vue";
export default {
  name: "WelcomeSplash",
  async created() {
    var webSetting = await this.fetchAsync();
    const website = this.$store.getters.getHost;
    const root = website.replaceAll(".", "");
    webSetting.logo = "/sites/" + root + "/logo.webp";
    webSetting.logo_bar = "/sites/" + root + "/logo_bar.png";
    //console.log(webSetting);
    this.$store.dispatch("set_manifest", webSetting);
  },
  components: {
    ai_loading,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false,
    },
    color: {
      type: String,
      default: "#000000",
    },
  },
  data() {
    return {
      logo: "null",
      loading: true,
    };
  },
  mounted() {
    const website = this.$store.getters.getHost;
    const root = website.replaceAll(".", "");
    const favicon = document.getElementById("favicon");
    favicon.href = root + "/favicon.ico";
    const root_manifest = "/manifests/" + website.replaceAll(".", "") + ".json";
    var mnfst = document.querySelectorAll('[rel="manifest"]');
    mnfst[0].href = root_manifest;
    setTimeout(this.closeSplash, 3000);
    //this.get_company();
  },
  computed: {
    imageStyle() {
      let width = this.size + "px";
      return {
        width,
      };
    },
  },
  methods: {
    fetchAsync() {
      const website = this.$store.getters.getHost;
      const root = website.replaceAll(".", "");
      let modulePath = "/manifests/" + root + ".json";
      this.logo = "/sites/" + root + "/logo.webp";
      return fetch(modulePath)
        .then((response) => response.text())
        .then((response) => JSON.parse(response));
    },
    setColor() {
      document.documentElement.style.setProperty("--splash-color", this.color);
    },
    closeSplash() {
      this.loading = false;
      this.$emit("close", true);
    },
  },
};
</script>
