<template>
  <div v-if="isLoading" class="loading-container">
    <div class="gradient-background"></div>
    <v-progress-circular
      v-for="n in 5"
      :key="n"
      :size="randomSize(200, 350)"
      :width="randomSize(2, 5)"
      :indeterminate="true"
      :color="randomColor()"
      :rotate="randomSize(0, 360)"
      class="outer-circle"
    >
    </v-progress-circular>

    <div class="core">
      <v-avatar size="180" class="claude-avatar">
        <v-img
          contain
          height="170"
          :src="logo"
          :alt="'Claude AI'"
          class="rounded-circle"
        ></v-img>
      </v-avatar>
    </div>

    <v-fade-transition>
      <div class="loading-text">{{ currentMessage }}</div>
    </v-fade-transition>

    <div class="claude-icon">
      <v-icon size="50" color="ocre">mdi-robot</v-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "FuturisticLoader",
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      logo: "",
      colors: ["cyan", "light-blue", "blue", "indigo", "deep-purple", "ocre"],
      messages: [
        "AI-POS is processing...",
        "AI-POS is analyzing data...",
        "AI-POS is optimizing inventory...",
        "AI-POS is generating insights...",
        "AI-POS is updating AI database...",
      ],
      currentMessageIndex: 0,
    };
  },
  computed: {
    currentMessage() {
      return this.messages[this.currentMessageIndex];
    },
  },
  beforeMount() {
    const website = this.$store.getters.getHost;
    const root = website.replaceAll(".", "");
    this.logo = `../sites/${root}/logo.webp`;
    console.log("Logo: " + this.logo);
  },
  mounted() {
    this.startMessageCycle();
  },
  beforeDestroy() {
    this.stopMessageCycle();
  },
  methods: {
    randomSize(a, b) {
      return Math.floor(Math.random() * (b - a + 1)) + a;
    },
    randomColor() {
      return this.colors[Math.floor(Math.random() * this.colors.length)];
    },
    startMessageCycle() {
      this.messageInterval = setInterval(() => {
        this.currentMessageIndex = (this.currentMessageIndex + 1) % this.messages.length;
      }, 3000);
    },
    stopMessageCycle() {
      clearInterval(this.messageInterval);
    },
  },
};
</script>

<style scoped>
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: hidden;
}

.gradient-background {
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(
    circle,
    rgba(0, 10, 30, 1) 0%,
    rgba(0, 10, 30, 0.8) 25%,
    rgba(0, 10, 30, 0.6) 50%,
    rgba(0, 10, 30, 0.4) 75%,
    rgba(0, 10, 30, 0.2) 100%
  );
  animation: rotate 20s linear infinite;
}

.outer-circle {
  position: absolute;
  animation: pulse 3s infinite ease-in-out;
}

.core {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  overflow: hidden;
  animation: glow 2s infinite alternate;
}

.claude-avatar {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent !important;
  border: 4px solid #d19c1d; /* Color ocre de Claude */
  box-shadow: 0 0 20px rgba(209, 156, 29, 0.7);
}

.claude-avatar .v-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.loading-text {
  position: absolute;
  bottom: 15%;
  font-size: 1.8em;
  color: white;
  text-shadow: 0 0 15px rgba(209, 156, 29, 0.7);
}

.claude-icon {
  position: absolute;
  top: 15%;
  animation: float 3s ease-in-out infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.05);
    opacity: 1;
  }
}

@keyframes glow {
  from {
    box-shadow: 0 0 15px rgba(209, 156, 29, 0.7), 0 0 30px rgba(209, 156, 29, 0.5);
  }
  to {
    box-shadow: 0 0 30px rgba(209, 156, 29, 0.7), 0 0 45px rgba(209, 156, 29, 0.5);
  }
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}

/* Definición del color ocre para Vuetify */
:root {
  --v-ocre-base: #d19c1d;
  --v-ocre-lighten5: #fff9c4;
  --v-ocre-lighten4: #ffecb3;
  --v-ocre-lighten3: #ffe082;
  --v-ocre-lighten2: #ffd54f;
  --v-ocre-lighten1: #ffca28;
  --v-ocre-darken1: #ffb300;
  --v-ocre-darken2: #ffa000;
  --v-ocre-darken3: #ff8f00;
  --v-ocre-darken4: #ff6f00;
}
</style>
